import {
    BULK_OPERATIONS,
    CLIENT, COACH_HOURS, COACH_SETTINGS,
    COACHES_IN_GROUPS,
    COURT,
    COURT_CATEGORY,
    COURT_VIDEO_ANALYTICS, GROUP,
    IMPLEMENTATION,
    IMPLEMENTATION_DOCUMENTS, INTERNET_ACQUIRING,
    LOGOUT,
    MEMBER_CARD,
    MEMBER_CARD_TYPE,
    ORGANIZATION,
    ORGANIZATION_LOCATION,
    PAYMENT,
    PAYMENT_DOCUMENTS,
    PAYMENT_SETTINGS,
    PRICE_MEMBER_CARDS,
    PRICE_SEASON,
    PRICE_TYPE,
    SCHEDULE_SLOT_RESERVE,
    SERVICE_APP,
    SERVICE_CATEGORY,
} from 'constants/routes';

import React, { FC } from 'react';
import {
    Navigate, Route, Routes, useLocation,
} from 'react-router-dom';
import { Toolbar } from '@mui/material';
import BulkOperations from 'page/BulkOperations';
import Client from 'page/Client';
import Coach from 'page/CoachCategory';
import CoachesInGroups from 'page/CoachesInGroups';
import CoachHours from 'page/CoachHours';
import Court from 'page/Court';
import CourtCategory from 'page/CourtCategory';
import CourtVideoAnalytics from 'page/CourtVideoAnalytics';
import GroupView from 'page/Group';
import Implementation from 'page/Implementation';
import ImplementationDocuments from 'page/ImplementationDocuments';
import InternetAcquiring from 'page/InternetAcquiring';
import Logout from 'page/Logout';
import MemberCard from 'page/MemberCard';
import MemberCardType from 'page/MemberCardType';
import Organization from 'page/Organization';
import OrganizationLocation from 'page/OrganizationLocation';
import Payment from 'page/Payment';
import PaymentDocument from 'page/PaymentDocument';
import PaymentSettings from 'page/PaymentSetting';
import PriceMemberCards from 'page/PriceMemberCard';
import Season from 'page/PriceSeason';
import PriceType from 'page/PriceType';
import ScheduleSlotReserve from 'page/ScheduleSlotReserve';
import ServiceApp from 'page/ServiceApp';
import ServiceCategory from 'page/ServiceCategory';
import Drawer from 'shared/component/Drawer';
import { AuthGuard } from 'shared/component/Guard';
import Header from 'shared/component/Header';

import { Container, RoutesWrap } from './styles';

const ProtectedRoutes: FC = () => {
    const location = useLocation();
    const path = location.pathname === '/' ? PRICE_SEASON : `/${location.pathname.split('/')[1]}`;

    return (
        <AuthGuard>
            <Container>
                <Header />
                <Drawer path={ path } />
                <RoutesWrap component="main">
                    <Toolbar />
                    <Routes>
                        <Route path="/" element={ <Navigate to={ PRICE_SEASON } /> } />
                        <Route path={ LOGOUT } element={ <Logout /> } />
                        <Route path={ `${PRICE_TYPE}/*` } element={ <PriceType /> } />
                        <Route path={ `${PRICE_SEASON}/*` } element={ <Season /> } />
                        <Route path={ `${PRICE_MEMBER_CARDS}/*` } element={ <PriceMemberCards /> } />
                        <Route path={ `${SERVICE_CATEGORY}/*` } element={ <ServiceCategory /> } />
                        <Route path={ `${MEMBER_CARD}/*` } element={ <MemberCard /> } />
                        <Route path={ `${MEMBER_CARD_TYPE}/*` } element={ <MemberCardType /> } />
                        <Route path={ `${BULK_OPERATIONS}/*` } element={ <BulkOperations /> } />
                        <Route path={ `${SCHEDULE_SLOT_RESERVE}/*` } element={ <ScheduleSlotReserve /> } />
                        <Route path={ `${IMPLEMENTATION}/*` } element={ <Implementation /> } />
                        <Route path={ `${IMPLEMENTATION_DOCUMENTS}/*` } element={ <ImplementationDocuments /> } />
                        <Route path={ `${COURT}/*` } element={ <Court /> } />
                        <Route path={ `${COURT_CATEGORY}/*` } element={ <CourtCategory /> } />
                        <Route path={ `${COURT_VIDEO_ANALYTICS}/*` } element={ <CourtVideoAnalytics /> } />
                        <Route path={ `${ORGANIZATION}/*` } element={ <Organization /> } />
                        <Route path={ `${ORGANIZATION_LOCATION}/*` } element={ <OrganizationLocation /> } />
                        <Route path={ `${COACH_SETTINGS}/*` } element={ <Coach /> } />
                        <Route path={ `${SERVICE_APP}/*` } element={ <ServiceApp /> } />
                        <Route path={ `${CLIENT}/*` } element={ <Client /> } />
                        <Route path={ `${COACH_HOURS}/*` } element={ <CoachHours /> } />
                        <Route path={ `${COACHES_IN_GROUPS}/*` } element={ <CoachesInGroups /> } />
                        <Route path={ `${PAYMENT_DOCUMENTS}/*` } element={ <PaymentDocument /> } />
                        <Route path={ `${PAYMENT}/*` } element={ <Payment /> } />
                        <Route path={ `${PAYMENT_SETTINGS}/*` } element={ <PaymentSettings /> } />
                        <Route path={ `${GROUP}/*` } element={ <GroupView /> } />
                        <Route path={ `${INTERNET_ACQUIRING}/*` } element={ <InternetAcquiring /> } />
                    </Routes>
                </RoutesWrap>
            </Container>
        </AuthGuard>
    );
};

export default ProtectedRoutes;
