import React from 'react';
import { Controller, FieldValues } from 'react-hook-form';
import {
    Box,
    FormControl, FormHelperText, InputLabel, MenuItem, Select as BaseSelect, Skeleton,
} from '@mui/material';

import { getErrorMessage } from '../../utils/getErrorMessage';

type OptionsType = {
    value: string | number;
    label: string;
}

type PropsT = {
    label?: string;
    loading?: boolean;
    options: OptionsType[];
    empty?: boolean;
    disabled?: boolean;
    customError?: boolean;
};

const Select = <TFieldValues extends FieldValues>(props: PropsT & TFieldValues) => {
    const {
        control, name, label, loading, options, empty, disabled, customError,
    } = props;

    return (
        <Controller
            name={ name }
            control={ control }
            render={ ({ field, fieldState }) => (
                <Box>
                    { label && <InputLabel>{ label }</InputLabel> }
                    {
                        loading ? <Skeleton variant="rounded" height={ 40 } />
                            : (
                                <FormControl fullWidth size="small" error={ !!fieldState.error }>
                                    <BaseSelect
                                        { ...field }
                                        disabled={ disabled }
                                        error={ !!fieldState.error }
                                    >
                                        {
                                            empty && (<MenuItem value="">Пустой</MenuItem>)
                                        }
                                        {
                                            options.map((item) => <MenuItem key={ item.value } value={ item.value }>{ item.label }</MenuItem>)
                                        }
                                    </BaseSelect>
                                    { !!fieldState.error && (
                                        <FormHelperText error>
                                            {
                                                customError ? fieldState.error?.message : getErrorMessage(fieldState)
                                            }
                                        </FormHelperText>
                                    ) }
                                </FormControl>
                            )
                    }
                </Box>
            ) }
        />
    );
};

export default Select;
