import {
    ERROR_MEMBER_CARD_CURRENT_DATE_LATER_THAN_EXP,
    ERROR_MEMBER_CARD_EXP_DATE_EARLIER_THAN_CURRENT,
} from 'constants/notificationMessage';
import { PROPERTY_END, PROPERTY_IS_ACTIVE, PROPERTY_START } from 'constants/propertyNames';

import dayjs, { Dayjs } from 'dayjs';
import { date, object } from 'yup';

export type UpdateMemberCardFormFields = {
    datetimeStart: Dayjs;
    datetimeEnd: Dayjs;
    isActive: boolean;
};

export const UPDATE_MEMBER_CARD_DEFAULT_VALUES: UpdateMemberCardFormFields = {
    datetimeStart: dayjs(),
    datetimeEnd: dayjs(),
    isActive: false,
};

export enum UPDATE_MEMBER_CARD_FIELDS_KEYS {
    datetimeStart = 'datetimeStart',
    datetimeEnd = 'datetimeEnd',
    isActive = 'isActive',
}

export const UPDATE_MEMBER_CARD_LABEL_DATA = {
    datetimeStart: PROPERTY_START,
    datetimeEnd: PROPERTY_END,
    isActive: PROPERTY_IS_ACTIVE,
};

export const getUpdateMemberCardValidationSchema = () => (
    object().shape({
        datetimeStart: date()
            .when('datetimeEnd', (datetimeEnd, schema) =>
                (datetimeEnd
                    ? schema.max(datetimeEnd, ERROR_MEMBER_CARD_CURRENT_DATE_LATER_THAN_EXP)
                    : schema))
            .required(),
        datetimeEnd: date()
            .when('datetimeStart', (datetimeStart, schema) =>
                (datetimeStart
                    ? schema.min(datetimeStart, ERROR_MEMBER_CARD_EXP_DATE_EARLIER_THAN_CURRENT)
                    : schema))
            .required(),
    }, [['datetimeStart', 'datetimeEnd']])
);
