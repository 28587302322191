import { API_DATE_TIME_FORMAT } from 'constants/date';

import { useMutation } from '@apollo/client';
import dayjs from 'dayjs';
import { UpdateMemberCardFormFields } from 'page/MemberCard/components/updateFormData';
import {
    MEMBER_CARD_UPDATE,
    MemberCardUpdateResponseDataT,
    MemberCardUpdateVar,
} from 'shared/graphql/mutation/memberCard/MemberCardUpdate';
import { MemberCardT } from 'types/MemberCard';

import { MemberCardUpdateDataT } from './MemberCardUpdateData';

const useMemberCardUpdate = (): MemberCardUpdateDataT => {
    const [update, { loading, error }] = useMutation<
        MemberCardUpdateResponseDataT,
        MemberCardUpdateVar
    >(MEMBER_CARD_UPDATE);

    const updateAction = async (
        input: UpdateMemberCardFormFields,
        memberCardId: MemberCardT['id'],
    ) => {
        if (!memberCardId) {
            // TODO: use Sentry
            throw new Error('MemberCard is not found');
        }

        try {
            const { data } = await update({
                variables: {
                    id: memberCardId,
                    set: {
                        datetime_start: dayjs(input.datetimeStart)
                            .startOf('day')
                            .format(API_DATE_TIME_FORMAT),
                        datetime_end: dayjs(input.datetimeEnd)
                            .startOf('day')
                            .format(API_DATE_TIME_FORMAT),
                        is_active: input.isActive,
                    },
                },
            });

            return data;
        } catch (errorUpdate) {
            // TODO: use Sentry
            console.error((errorUpdate as Error).message); // eslint-disable-line
            throw errorUpdate;
        }
    };

    return {
        memberCardUpdate: updateAction,
        loading,
        error,
    };
};

export default useMemberCardUpdate;
