import { DEFAULT_LOCALE } from 'constants/locale';
import {
    PROPERTY_DEFAULT_CREDIT_FOR_NEW_CLIENTS,
    PROPERTY_DESCRIPTION,
    PROPERTY_DOMAIN_NAME,
    PROPERTY_FIAT_CURRENCY_TITLE,
    PROPERTY_HASH,
    PROPERTY_LABEL,
    PROPERTY_LOCALE,
    PROPERTY_SMS_SENDER_NAME,
    PROPERTY_SMS_SENDING_ENABLED,
    PROPERTY_TIMEZONE,
    PROPERTY_TOKEN,
    PROPERTY_URL,
    PROPERTY_VIDEO_ANALYTICS_BOT_IDS,
} from 'constants/propertyNames';
import { DEFAULT_SITE_TITLE } from 'constants/siteTitle';
import { DEFAULT_TIMEZONE } from 'constants/timezone';

import { omit } from 'lodash';
import {
    array, bool, number, object, string,
} from 'yup';

export type ServiceAppFormFields = {
    label: string;
    token: string;
    description: string;
    url: string;
    hash: string;
    timezone: number;
    fiatCurrencyTitle: string;
    videoAnalyticsTelegramBotChatIds: Array<{
        item: string;
    }>;
    domainName: string;
    smsSenderName: string;
    smsSendingEnabled: boolean;
    locale: string;
    defaultCreditForNewClients: number;
};

export const SERVICE_APP_DEFAULT_VALUES: ServiceAppFormFields = {
    label: DEFAULT_SITE_TITLE,
    token: '',
    description: DEFAULT_SITE_TITLE,
    url: '',
    hash: '',
    timezone: DEFAULT_TIMEZONE,
    fiatCurrencyTitle: '',
    videoAnalyticsTelegramBotChatIds: [],
    domainName: '',
    smsSenderName: '',
    smsSendingEnabled: false,
    locale: DEFAULT_LOCALE,
    defaultCreditForNewClients: 0,
};

export const SERVICE_APP_FIELDS = Object.keys(omit(SERVICE_APP_DEFAULT_VALUES, ['timezone', 'videoAnalyticsTelegramBotChatIds', 'smsSendingEnabled', 'locale']));

export enum SERVICE_APP_FIELDS_KEYS {
    label = 'label',
    token = 'token',
    description = 'description',
    url = 'url',
    hash = 'hash',
    timezone = 'timezone',
    fiatCurrencyTitle = 'fiatCurrencyTitle',
    videoAnalyticsTelegramBotChatIds = 'videoAnalyticsTelegramBotChatIds',
    domainName = 'domainName',
    smsSenderName = 'smsSenderName',
    smsSendingEnabled = 'smsSendingEnabled',
    locale = 'locale',
    defaultCreditForNewClients = 'defaultCreditForNewClients',
}

type ServiceAppLabelDataT = Record<keyof ServiceAppFormFields, string>;

export const SERVICE_APP_LABEL_DATA: ServiceAppLabelDataT = {
    label: PROPERTY_LABEL,
    token: PROPERTY_TOKEN,
    description: PROPERTY_DESCRIPTION,
    url: PROPERTY_URL,
    hash: PROPERTY_HASH,
    timezone: PROPERTY_TIMEZONE,
    fiatCurrencyTitle: PROPERTY_FIAT_CURRENCY_TITLE,
    videoAnalyticsTelegramBotChatIds: PROPERTY_VIDEO_ANALYTICS_BOT_IDS,
    domainName: PROPERTY_DOMAIN_NAME,
    smsSenderName: PROPERTY_SMS_SENDER_NAME,
    smsSendingEnabled: PROPERTY_SMS_SENDING_ENABLED,
    locale: PROPERTY_LOCALE,
    defaultCreditForNewClients: PROPERTY_DEFAULT_CREDIT_FOR_NEW_CLIENTS,
};

export const SERVICE_APP_VALIDATION_SCHEMA = object().shape({
    label: string().required(),
    token: string().required(),
    description: string().required(),
    url: string().required(),
    hash: string().required(),
    timezone: number().required(),
    fiatCurrencyTitle: string().required(),
    videoAnalyticsTelegramBotChatIds: array().of(
        object().shape({
            item: string().required(),
        }),
    ),
    domainName: string().required(),
    smsSenderName: string().required(),
    smsSendingEnabled: bool().required(),
    locale: string().required(),
    defaultCreditForNewClients: number().required(),
});
