import { MONTHS_LIST } from 'constants/MonthsList';
import {
    PROPERTY_ENABLED_AT_MONTH,
    PROPERTY_IS_PERFORMER_INCOME_RECALCULATING,
    PROPERTY_IS_PERFORMER_INCOME_RECALCULATING_EXTENDED,
    PROPERTY_NUMBER_FULL_RETURNS,
    PROPERTY_ROUNDS_SALES,
} from 'constants/propertyNames';

import { FC, memo } from 'react';
import { Control, useFieldArray } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Button, IconButton } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { observer } from 'mobx-react';
import AuxiliaryPopover from 'shared/component/AuxiliaryPopover';
import Switch from 'shared/component/Form/Switch';
import TextField from 'shared/component/Form/TextField';
import { useStore } from 'store';

import FieldSelect from './FieldSelect';
import { MEMBER_CARD_TYPE_DISCOUNT_FULL_RETURN_DEFAULT_VALUES, PriceMemberCardFormFields } from './formData';

interface PropsT {
    control: Control<PriceMemberCardFormFields>;
    loadingPriceSalesRounds: boolean;
}

const FullReturnForm: FC<PropsT> = observer((props) => {
    const { control } = props;
    const priceSalesRoundOptions = useStore('filterOptions').getFilterOption('priceSalesRound');

    const { fields, insert, remove } = useFieldArray<PriceMemberCardFormFields>({
        control,
        name: 'memberCardTypeDiscountFullReturns',
    });

    const handleAdd = (index: number) => {
        insert(index + 1, MEMBER_CARD_TYPE_DISCOUNT_FULL_RETURN_DEFAULT_VALUES);
    };

    return (
        <Grid2 container rowSpacing={ 4 }>
            { fields.map((field, index) => (
                <Grid2 xs={ 12 } container alignItems="flex-start" columnSpacing={ 2 } rowSpacing={ 2 } key={ field.id || index }>
                    <Grid2 xs={ 6 } pt={ 1 } container columnSpacing={ 2 }>
                        <Grid2 xs={ 5 }>
                            <TextField
                                control={ control }
                                label={ PROPERTY_NUMBER_FULL_RETURNS }
                                name={ `memberCardTypeDiscountFullReturns.${index}.fullReturnQuantityLimit` }
                                type="number"
                            />
                        </Grid2>
                        <Grid2 xs={ 7 }>
                            <FieldSelect
                                label={ PROPERTY_ENABLED_AT_MONTH }
                                name={ `memberCardTypeDiscountFullReturns.${index}.enabledAtMonths` }
                                options={ MONTHS_LIST }
                                control={ control }
                            />
                        </Grid2>
                        <Grid2 xs={ 12 } display="flex">
                            <Switch
                                control={ control }
                                label={ PROPERTY_IS_PERFORMER_INCOME_RECALCULATING }
                                name={ `memberCardTypeDiscountFullReturns.${index}.isPerformerIncomeRecalculating` }
                                type="boolean"
                            />
                            <AuxiliaryPopover
                                auxiliaryPopoverText={ PROPERTY_IS_PERFORMER_INCOME_RECALCULATING_EXTENDED }
                            />
                        </Grid2>
                    </Grid2>
                    <Grid2 xs={ 5 } container>
                        <Grid2 xs={ 11 }>
                            <FieldSelect
                                options={ priceSalesRoundOptions }
                                control={ control }
                                label={ PROPERTY_ROUNDS_SALES }
                                name={ `memberCardTypeDiscountFullReturns.${index}.memberCardTypeDiscountFullReturnPriceSalesRounds` }
                            />
                        </Grid2>
                        <Grid2 xs={ 1 } display="flex" alignItems="center">
                            <IconButton
                                color="info"
                                onClick={ () => handleAdd(index) }
                            >
                                <AddIcon />
                            </IconButton>
                            <IconButton
                                color="error"
                                onClick={ () => remove(index) }
                            >
                                <RemoveIcon />
                            </IconButton>
                        </Grid2>
                    </Grid2>
                </Grid2>
            )) }
            { fields.length === 0 && (
                <Grid2>
                    <Button
                        variant="outlined"
                        startIcon={ <AddIcon /> }
                        onClick={ () => handleAdd(0) }
                    >
                        Добавить поле
                    </Button>
                </Grid2>
            ) }
        </Grid2>
    );
});

export default memo(FullReturnForm);
