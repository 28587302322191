import { useLazyQuery } from '@apollo/client';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import {
    MEMBER_CARDS_QUERY,
    MemberCardsResponseT,
} from 'shared/graphql/query/memberCard/MemberCardQuery';
import { QueryVarsT } from 'types/Graphql';
import { EMPTY_MEMBER_CARDS_RESULT, FetchMemberCardDataParams } from 'types/MemberCard';

import { MemberCardsDTO } from '../DTO/MemberCardsDTO';
import { getFullNameFilter } from '../filter/getFullNameFilter';

import { MemberCardsDataT } from './MemberCardsData';

const useMemberCards = (): MemberCardsDataT => {
    const [fetch, { data, loading }] = useLazyQuery<MemberCardsResponseT, QueryVarsT>(
        MEMBER_CARDS_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    const decodedMemberCardData = decodeDTO(
        MemberCardsDTO,
        EMPTY_MEMBER_CARDS_RESULT,
        (memberCards) => memberCards,
        {
            memberCards: data?.memberCards,
            memberCardsCount: data?.memberCardsCount,
        },
    );

    const fetchMemberCards = async ({
        limit, offset, orderBy = { client: { last_name: 'asc' } }, search = { name: '' }, where = { items: [], linkOperator: 'and' },
    }: FetchMemberCardDataParams = {}): Promise<void> => {
        try {
            const searchParams = getFullNameFilter(search, where);

            await fetch({
                variables: {
                    limit,
                    offset,
                    orderBy,
                    where: searchParams,
                },
            });
        } catch (error) {
            // TODO: use Sentry
            console.error((error as Error).message); // eslint-disable-line
            throw error;
        }
    };

    return {
        data: decodedMemberCardData.memberCards,
        count: decodedMemberCardData.memberCardsCount,
        fetchMemberCards,
        loading,
    };
};

export default useMemberCards;
