import { BONUS, FIAT } from 'constants/currency';
import { OPERATION_CANCEL, OPERATION_TRANSFER } from 'constants/operations';
import {
    PROPERTY_FORWARD_TRANSFER_COST_BACK_TRANSFER_COST,
    PROPERTY_FORWARD_TRANSFER_COST_BACK_TRANSFER_COST_EXTENDED,
    PROPERTY_MAX_BONUS,
    PROPERTY_MAX_FIAT,
    PROPERTY_MIN_RATE,
    PROPERTY_PERFORMER_RETURN_INCOME_VALUE,
    PROPERTY_PERFORMER_RETURN_INCOME_VALUE_EXTENDED,
    PROPERTY_PERFORMER_SALE_INCOME_VALUE_EXTENDED,
    PROPERTY_PROPERTY_PERFORMER_SALE_INCOME_VALUE,
    PROPERTY_RETURN_RATE, PROPERTY_SALE_RATE,
    PROPERTY_TRANSFER_DISABLED_BEFORE_HOURS,
    PROPERTY_TRANSFER_DISABLED_BEFORE_HOURS_HELPER_TEXT,
} from 'constants/propertyNames';

import React, { FC } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { Typography } from '@mui/material';
import {
    GridCellParams, GridColumns, GridRenderCellParams, GridValueGetterParams,
} from '@mui/x-data-grid-premium';
import AuxiliaryPopover from 'shared/component/AuxiliaryPopover';
import { PriceRateT } from 'types/PriceRate';
import { PriceSalesRoundT } from 'types/PriceSalesRound';

type BoolIconT = 'cancellable' | 'transferable';
type CellT = 'saleRate' | 'minRate' | 'returnRate' | 'shareOfPaymentBonus' | 'shareOfPaymentFiat' | 'transferDisabledBeforeHours';

type HeaderDataT<T> = {
    params: GridCellParams;
    property: T;
    id: string;
}

const RenderBoolIcon: FC<HeaderDataT<BoolIconT>> = ({ params, property, id }) => {
    const priceRate = params.row.priceRates.find((item: PriceRateT) => item.priceSalesRoundId === id);

    if (!priceRate) {
        return <> </>;
    }

    return priceRate[property] ? <DoneIcon color="success" /> : <CloseIcon color="error" />;
};

const renderCell = ({ params, property, id }: HeaderDataT<CellT>): string | null => {
    const priceRate = params.row.priceRates.find((item: PriceRateT) => item.priceSalesRoundId === id);

    return priceRate ? priceRate[property] : null;
};

export const setHeaderData = (headerData: GridColumns, priceSalesRound: PriceSalesRoundT, index: number) => {
    headerData.push(
        {
            field: `saleRate-${index}`,
            headerName: PROPERTY_SALE_RATE,
            align: 'center',
            headerAlign: 'center',
            width: 150,
            type: 'number',
            headerClassName: 'data-grid-sale-column',
            cellClassName: 'data-grid-sale-column',
            valueGetter: (params: GridValueGetterParams) => renderCell({ params, property: 'saleRate', id: priceSalesRound.id }),
        },
        {
            field: `shareOfPaymentFiat-${index}`,
            align: 'center',
            headerAlign: 'center',
            width: 120,
            type: 'number',
            headerClassName: 'data-grid-sale-column',
            cellClassName: 'data-grid-sale-column',
            valueGetter: (params: GridValueGetterParams) => renderCell({ params, property: 'shareOfPaymentFiat', id: priceSalesRound.id }),
            renderHeader: () => (
                <>
                    <Typography variant="subtitle2" sx={ { fontWeight: '500' } }>{ FIAT }</Typography>
                    <AuxiliaryPopover
                        auxiliaryPopoverText={ PROPERTY_MAX_FIAT }
                        auxiliaryPopoverIconSize="small"
                    />
                </>
            ),
        },
        {
            field: `shareOfPaymentBonus-${index}`,
            align: 'center',
            headerAlign: 'center',
            width: 120,
            type: 'number',
            headerClassName: 'data-grid-sale-column',
            cellClassName: 'data-grid-sale-column',
            valueGetter: (params: GridValueGetterParams) => renderCell({ params, property: 'shareOfPaymentBonus', id: priceSalesRound.id }),
            renderHeader: () => (
                <>
                    <Typography variant="subtitle2" sx={ { fontWeight: '500' } }>{ BONUS }</Typography>
                    <AuxiliaryPopover
                        auxiliaryPopoverText={ PROPERTY_MAX_BONUS }
                        auxiliaryPopoverIconSize="small"
                    />
                </>
            ),
        },
        {
            field: `minRate-${index}`,
            headerName: PROPERTY_MIN_RATE,
            align: 'center',
            headerAlign: 'center',
            width: 200,
            type: 'number',
            headerClassName: 'data-grid-sale-column',
            cellClassName: 'data-grid-sale-column',
            valueGetter: (params: GridValueGetterParams) => renderCell({ params, property: 'minRate', id: priceSalesRound.id }),
        },
        {
            field: `performerSaleIncomeValue-${index}`,
            align: 'center',
            headerAlign: 'center',
            width: 300,
            type: 'number',
            headerClassName: 'data-grid-sale-column',
            cellClassName: 'data-grid-sale-column',
            valueGetter: (params: GridValueGetterParams) => {
                const priceRate = params.row.priceRates.find((item: PriceRateT) => item.priceSalesRoundId === priceSalesRound.id);

                if (!priceRate) {
                    return null;
                }

                return `${priceRate.performerSaleIncomeValue} ${priceRate.performerSaleIncomeCurrency}`;
            },
            renderHeader: () => (
                <>
                    <Typography variant="subtitle2" sx={ { fontWeight: '500' } }>{ PROPERTY_PROPERTY_PERFORMER_SALE_INCOME_VALUE }</Typography>
                    <AuxiliaryPopover
                        auxiliaryPopoverText={ PROPERTY_PERFORMER_SALE_INCOME_VALUE_EXTENDED }
                        auxiliaryPopoverIconSize="small"
                    />
                </>
            ),
        },
        {
            field: `returnRate-${index}`,
            headerName: PROPERTY_RETURN_RATE,
            align: 'center',
            headerAlign: 'center',
            width: 170,
            type: 'number',
            headerClassName: 'data-grid-cancel-column',
            cellClassName: 'data-grid-cancel-column',
            valueGetter: (params: GridValueGetterParams) => renderCell({ params, property: 'returnRate', id: priceSalesRound.id }),
        },
        {
            field: `performerReturnIncomeValue-${index}`,
            align: 'center',
            headerAlign: 'center',
            width: 300,
            type: 'number',
            headerClassName: 'data-grid-cancel-column',
            cellClassName: 'border-right data-grid-cancel-column',
            valueGetter: (params: GridValueGetterParams) => {
                const priceRate = params.row.priceRates.find((item: PriceRateT) => item.priceSalesRoundId === priceSalesRound.id);

                if (!priceRate) {
                    return null;
                }

                return `${priceRate.performerReturnIncomeValue} ${priceRate.performerReturnIncomeCurrency}`;
            },
            renderHeader: () => (
                <>
                    <Typography variant="subtitle2" sx={ { fontWeight: '500' } }>{ PROPERTY_PERFORMER_RETURN_INCOME_VALUE }</Typography>
                    <AuxiliaryPopover
                        auxiliaryPopoverText={ PROPERTY_PERFORMER_RETURN_INCOME_VALUE_EXTENDED }
                        auxiliaryPopoverIconSize="small"
                    />
                </>
            ),
        },
        {
            field: `cancellable-${index}`,
            headerName: OPERATION_CANCEL,
            align: 'center',
            headerAlign: 'center',
            width: 130,
            headerClassName: 'data-grid-cancel-column',
            cellClassName: 'data-grid-cancel-column',
            renderCell: (params: GridRenderCellParams) => <RenderBoolIcon params={ params } property="cancellable" id={ priceSalesRound.id } />,
        },
        {
            field: `transferable-${index}`,
            headerName: OPERATION_TRANSFER,
            align: 'center',
            headerAlign: 'center',
            width: 130,
            type: 'boolean',
            headerClassName: 'data-grid-information-column',
            cellClassName: 'data-grid-information-column',
            renderCell: (params: GridRenderCellParams) => <RenderBoolIcon params={ params } property="transferable" id={ priceSalesRound.id } />,
        },
        {
            field: `transferDisabledBeforeHours-${index}`,
            width: 230,
            align: 'center',
            headerAlign: 'center',
            type: 'number',
            headerClassName: 'data-grid-information-column',
            cellClassName: 'data-grid-information-column',
            valueGetter: (params: GridValueGetterParams) => renderCell({ params, property: 'transferDisabledBeforeHours', id: priceSalesRound.id }),
            renderHeader: () => (
                <>
                    <Typography variant="subtitle2" sx={ { fontWeight: '500' } }>{ PROPERTY_TRANSFER_DISABLED_BEFORE_HOURS }</Typography>
                    <AuxiliaryPopover
                        auxiliaryPopoverText={ PROPERTY_TRANSFER_DISABLED_BEFORE_HOURS_HELPER_TEXT }
                        auxiliaryPopoverIconSize="small"
                    />
                </>
            ),
        },
        {
            field: `transferCost-${index}`,
            align: 'center',
            headerAlign: 'center',
            width: 300,
            type: 'number',
            headerClassName: 'data-grid-information-column',
            cellClassName: 'data-grid-information-column',
            valueGetter: (params: GridValueGetterParams) => {
                const priceRate = params.row.priceRates.find((item: PriceRateT) => item.priceSalesRoundId === priceSalesRound.id);

                if (!priceRate) {
                    return null;
                }

                return `${priceRate.backTransferCost} / ${priceRate.forwardTransferCost}`;
            },
            renderHeader: () => (
                <>
                    <Typography variant="subtitle2" sx={ { fontWeight: '500' } }>{ PROPERTY_FORWARD_TRANSFER_COST_BACK_TRANSFER_COST }</Typography>
                    <AuxiliaryPopover
                        auxiliaryPopoverText={ PROPERTY_FORWARD_TRANSFER_COST_BACK_TRANSFER_COST_EXTENDED }
                        auxiliaryPopoverIconSize="small"
                    />
                </>
            ),
        },
    );
};
