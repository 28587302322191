import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import Nullable from 'shared/data-hook/utils/DTO/Nullable';
import { ServiceAppT } from 'types/serviceApp/ServiceApp';

const DTO = t.type({
    id: t.string,
    label: t.string,
    token: t.string,
    description: t.string,
    url: t.string,
    hash: t.string,
    timezone: t.number,
    fiat_currency_title: t.string,
    video_analytics_telegram_bot_chat_ids: t.array(t.string),
    domain_name: Nullable(t.string),
    sms_sending_enabled: Nullable(t.boolean),
    sms_sender_name: Nullable(t.string),
    locale: Nullable(t.string),
    default_credit_for_new_clients: Nullable(t.number),
});

export type ServiceAppDTOT = t.TypeOf<typeof DTO>;

export const ServiceAppDTO = tPromise.extendDecoder<ServiceAppDTOT, ServiceAppT>(
    DTO,
    ({
        id,
        label,
        token,
        description,
        url,
        hash,
        timezone,
        fiat_currency_title,
        video_analytics_telegram_bot_chat_ids,
        domain_name,
        sms_sending_enabled,
        sms_sender_name,
        locale,
        default_credit_for_new_clients,
    }) => ({
        id,
        label,
        token,
        description,
        url,
        hash,
        timezone,
        fiatCurrencyTitle: fiat_currency_title,
        videoAnalyticsTelegramBotChatIds: video_analytics_telegram_bot_chat_ids,
        domainName: domain_name,
        smsSendingEnabled: sms_sending_enabled,
        smsSenderName: sms_sender_name,
        locale,
        defaultCreditForNewClients: default_credit_for_new_clients,
    }),
) as t.Type<ServiceAppT, ServiceAppDTOT>;
