import { ClientFullNameT } from './Client';
import { SortT, WhereT } from './DataGrid';

export type MemberCardT = {
    id: string;
    title: string;
    isActive: boolean;
    datetimeStart: Date;
    datetimeEnd: Date;
    client: ClientFullNameT;
};

export type MemberCardsT = {
    memberCards: MemberCardT[];
    memberCardsCount: number;
};

export type FetchMemberCardDataParams = {
    limit?: number;
    offset?: number;
    orderBy?: SortT;
    where?: WhereT;
    search?: { name: string };
};

export const EMPTY_MEMBER_CARD_RESULT: MemberCardT = {
    id: '',
    title: '',
    isActive: false,
    datetimeStart: new Date(0),
    datetimeEnd: new Date(0),
    client: {
        firstName: null,
        lastName: null,
        middleName: null,
    },
};

export const EMPTY_MEMBER_CARDS_RESULT: MemberCardsT = {
    memberCards: [],
    memberCardsCount: 0,
};
