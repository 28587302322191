import { MODAL_TITLE_UPDATE_ACQUIRING_SETTING } from 'constants/modalTitles';

import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, DialogContent } from '@mui/material';
import { observer } from 'mobx-react';
import { Modal } from 'shared/component/Modal';
import ModalTitle from 'shared/component/Modal/ModalTitle';
import { Spinner } from 'shared/component/Spinner';
import useFetchAcquiringSetting from 'shared/hook/acquiringSettings/fetch/fetchOne/useFetchAcquiringSetting';
import useUpdateAcquiringSetting from 'shared/hook/acquiringSettings/update/useUpdateAcquiringSetting';
import { useStore } from 'store';

import AcquiringSettingForm from './AcquiringSettingForm';
import {
    ACQUIRING_SETTING_DEFAULT_VALUES,
    AcquiringSettingFormFields,
    getAcquiringSettingValidationSchema,
} from './formData';

const UpdateAcquiringSettingModal: FC = observer(() => {
    const {
        isOpen, onClose, acquiringSetting, itemId: acquiringSettingId,
    } = useStore('updateAcquiringSettingModal');
    const { loading: loadingFetch } = useFetchAcquiringSetting(acquiringSettingId);
    const { updateAcquiringSetting, loading: loadingUpdate } = useUpdateAcquiringSetting();
    const {
        control, handleSubmit, reset, setValue, formState: { isValid, isDirty },
    } = useForm<AcquiringSettingFormFields>({
        defaultValues: ACQUIRING_SETTING_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(getAcquiringSettingValidationSchema()),
    });

    const handleCloseModal = () => {
        reset();
        onClose();
    };

    const handleUpdateAcquiringSetting = async (data: AcquiringSettingFormFields) => {
        await updateAcquiringSetting(data, acquiringSettingId);
        handleCloseModal();
    };

    return (
        <Modal open={ isOpen } maxWidth="sm">
            <ModalTitle onClose={ handleCloseModal }>
                { MODAL_TITLE_UPDATE_ACQUIRING_SETTING }
            </ModalTitle>
            <DialogContent dividers={ true }>
                { loadingFetch && <Spinner /> }
                <AcquiringSettingForm
                    operationType="update"
                    control={ control }
                    setValue={ setValue }
                    acquiringSetting={ acquiringSetting }
                />
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    color="error"
                    onClick={ () => {
                        reset({
                            ...acquiringSetting,
                            serviceApp: acquiringSetting.serviceApp?.id,
                            paymentMethod: acquiringSetting.paymentMethod?.id,
                            paymentType: acquiringSetting.paymentType?.id,
                        });
                    } }
                >
                    Сбросить
                </Button>
                <Button variant="outlined" onClick={ handleCloseModal }>
                    Отмена
                </Button>
                <LoadingButton
                    variant="contained"
                    onClick={ handleSubmit(handleUpdateAcquiringSetting) }
                    autoFocus
                    disabled={ !isValid || !isDirty }
                    loading={ loadingUpdate }
                >
                    Обновить
                </LoadingButton>
            </DialogActions>
        </Modal>
    );
});

export default UpdateAcquiringSettingModal;
