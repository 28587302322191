import { filterData } from '../../../utils/filter/filterData';

export const addParamsToName = (name: string) => ({
    client: {
        full_name: {
            _iregex: name.replaceAll(' ', '.*'),
        },
    },
});

export const getFullNameFilter = (search: { name: string }, where: any) => {
    const searchParams = {
        ...filterData(where),
        ...(search.name && addParamsToName(search.name)),
    };

    return searchParams;
};
