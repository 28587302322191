import { PAGE_TITLE_INTERNET_ACQUIRING } from 'constants/pageTitles';

import React, { FC, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { observer } from 'mobx-react';
import ControlPanel from 'shared/component/ControlPanel';
import useAcquiringSettings from 'shared/data-hook/acquiringSetting/fetch/fetchAll/useAcquiringSettings';
import { PAYMENT_METHOD_FILTER_OPTIONS_QUERY } from 'shared/graphql/query/paymentMethod/PaymentMethodQuery';
import { PAYMENT_TYPE_FILTER_OPTIONS_QUERY } from 'shared/graphql/query/paymentType/PaymentTypeQuery';
import { SERVICE_APP_FILTER_OPTIONS_QUERY } from 'shared/graphql/query/serviceApp/ServiceAppQuery';
import { useDataGrid } from 'shared/hook/dataGrid/useDataGrid';
import useFetchDataForGrid from 'shared/hook/dataGrid/useFetchDataForGrid';
import useFetchFilterOptions from 'shared/hook/filterOptions/useFetchFilterOptions';
import usePageTitle from 'shared/hook/usePageTitle';
import { StyledPageWrap } from 'shared/styled/styledPageWrap';
import { useStore } from 'store';

import CreateInternetAcquiringModal from './components/CreateInternetAcquiringModal';
import InternetAcquiringListComponent from './components/InternetAcquiringList';
import UpdateInternetAcquiringModal from './components/UpdateInternetAcquiringModal';
import { INTERNET_ACQUIRING_HEADER_DATA } from './domain/HeaderData';

const InternetAcquiringList: FC = observer(() => {
    const {
        pageSize,
        setPageSize,
        page,
        setPage,
        sortModel,
        setSortModel,
        filterModel,
        setFilterModel,
    } = useDataGrid();

    const {
        data: acquiringSettingsData,
        loading: acquiringSettingsLoading,
        count: rowCount,
        fetchAcquiringSettings,
    } = useAcquiringSettings();

    const { loading: paymentMethodsLoading } = useFetchFilterOptions(
        PAYMENT_METHOD_FILTER_OPTIONS_QUERY,
        'paymentMethod',
    );
    const { loading: paymentTypesLoading } = useFetchFilterOptions(
        PAYMENT_TYPE_FILTER_OPTIONS_QUERY,
        'paymentType',
    );
    const { loading: serviceAppLoading } = useFetchFilterOptions(
        SERVICE_APP_FILTER_OPTIONS_QUERY,
        'serviceApp',
    );
    const { setAcquiringSettings } = useStore('acquiringSettings');
    const { refetchTrigger } = useStore('refetchTrigger');
    const { onOpen } = useStore('createAcquiringSettingModal');
    const loading =
        acquiringSettingsLoading || paymentTypesLoading || paymentMethodsLoading || serviceAppLoading;

    usePageTitle(PAGE_TITLE_INTERNET_ACQUIRING);
    useFetchDataForGrid(
        sortModel,
        filterModel,
        page,
        pageSize,
        fetchAcquiringSettings,
        refetchTrigger,
        INTERNET_ACQUIRING_HEADER_DATA,
    );
    useEffect(() => {
        setAcquiringSettings(acquiringSettingsData, rowCount);
    }, [acquiringSettingsData, acquiringSettingsLoading]);

    return (
        <StyledPageWrap>
            <ControlPanel>
                <Button
                    variant="outlined"
                    size="small"
                    onClick={ () => onOpen() }
                    startIcon={ <AddIcon /> }
                >
                    добавить
                </Button>
            </ControlPanel>
            <InternetAcquiringListComponent
                loading={ loading }
                pageSize={ pageSize }
                setPageSize={ setPageSize }
                page={ page }
                setPage={ setPage }
                setSortModel={ setSortModel }
                setFilterModel={ setFilterModel }
                sortModel={ sortModel }
                filterModel={ filterModel }
            />
            <CreateInternetAcquiringModal />
            <UpdateInternetAcquiringModal />
        </StyledPageWrap>
    );
});

export default InternetAcquiringList;
